<template>
  <div id="links-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <!--<router-link v-if="nextApproval <= 0" to="/scraper/single" class="btn btn-primary mb-5">
      Einzelne Seite scrapen
    </router-link>-->

    <div v-if="nextApproval <= 0 && step < 3" class="row justify-content-center mb-4">
      <div class="col-sm-11 col-md-7">
        <EditProgress :steps="editSteps" :step-index="step" @setStep="step = $event" :skippable="false"></EditProgress>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div v-if="scraperRunning || scraperScheduled" class="card p-5 mx-auto mt-4 text-center">
          <i class="fad fa-cloud-download fa-5x text-primary"></i>
          <h3 class="mt-4">
            {{ scraperUpdate === 'Scrape beendet' ? 'Scrape abgeschlossen' : 'Der Scraper läuft.' }}
          </h3>
          <div v-if="scraperUpdate === 'Scrape beendet'">
            <router-link class="btn btn-primary mt-4 mx-1" to="/links">Ergebnis im Link Tree ansehen</router-link>
          </div>
          <div v-else>
            <p v-if="scraperScheduled">
              <code>Dein Scrape wurde in die Warteschlange eingereiht.</code>
            </p>
            <p v-else>
              <code><span class="transparent">{{ waitingDots }}</span>{{ scraperUpdate }}{{ waitingDots }}</code>
            </p>
            <p v-if="scraperScheduled" class="small">
              Der Scraper arbeitet zur Zeit noch an anderen Projekten. <br>
              Sollte nach vier Stunden noch nichts passiert sein, kannst du eine Mail an
              <a href="mailto:alexander@fuerst.one">alexander@fuerst.one</a> schreiben.
            </p>
            <p v-else>
              Es kann je nach Seitengröße und Anfragevolumen eine Weile dauern bis der Scraping-Vorgang abgeschlossen
              ist. Hol dir ein erfrischendes Getränk und komm einfach in ein paar Minuten wieder.
            </p>
            <div v-if="project.theories.length">
              <h6 class="mt-3">Was nun?</h6>
              <p>
                Du kannst während du auf den Scraper wartest zum Beispiel beginnen, deinen Content aus deinen Social Media Plattformen zu pflegen
                und zu organisieren.
              </p>
              <button class="btn btn-secondary mt-4 mx-1" @click="cancelScrape">
                Scrape abbrechen
              </button>
              <router-link class="btn btn-primary mt-4 mx-1" to="/contents/add">
                Content-Pflege beginnen
              </router-link>
            </div>
            <div v-else>
              <h6 class="mt-3">Wie geht es weiter?</h6>
              <p>
                Während der Scraper deine Seite ausliest und Screenshots erstellt, kannst du bereits anfangen deine erste
                Optimierungs-Theorie zu erstellen, um deine Reise zu starten.
              </p>
              <button class="btn btn-secondary mt-4 mx-1" @click="cancelScrape">
                Scrape abbrechen
              </button>
              <router-link class="btn btn-primary mt-4 mx-1" to="/theories">
                Jetzt deine erste Theorie erstellen
              </router-link>
            </div>
          </div>
        </div>
        <div v-else-if="scraperError" class="card p-5 mx-auto mt-4 text-center">
          <i class="fad fa-cloud-download fa-5x text-primary"></i>
          <h3 class="mt-4">
            Oops.
          </h3>
          <p>
            Der Scraper hatte einen Fehler beim Scrapen. <br>
            Probier es am besten einfach nochmal.
          </p>
          <p class="small">
            Wenn dieser Fehler öfter auftritt, schreibe bitte eine Mail an
            <a href="mailto:alexander@fuerst.one">alexander@fuerst.one</a>.
          </p>
          <div>
            <button class="btn btn-primary mt-4 mr-3" @click="retryScrape">Erneut versuchen</button>
            <button class="btn btn-primary mt-4" @click="resetScraper">Scrape neu erstellen</button>
          </div>
        </div>
        <div v-else-if="nextApproval > 0" class="card p-5 mx-auto mt-4 text-center">
          <i class="fad fa-exclamation-circle fa-5x text-warning"></i>
          <h3 class="mt-4">
            Dein Scraping-Kontingent ist erschöpft.
          </h3>
          <p>
            Komm {{ nextScrape }} wieder oder schreibe eine E-Mail an
            <a href="mailto:alexander@fuerst.one">alexander@fuerst.one</a>.
          </p>
          <div>
            <button @click="$router.back()" class="btn btn-secondary mt-4 mx-1">Zurück zur letzten Seite</button>
            <!--<router-link to="/scraper/single" class="btn btn-primary mt-4 mx-1">Einzelne Seite scrapen</router-link>-->
            <router-link to="/contents/add" class="btn btn-primary mt-4 mx-1">Seite manuell hinzufügen</router-link>
          </div>
        </div>
        <div v-else>
          <div class="card mx-auto">
            <div class="card-header">
              <h3 class="my-0">Neuer Seiten-Scrape</h3>
            </div>
            <div class="card-body">
              <p>
                Setze die Einstellungen, um deine Website zu scrapen und Screenshots und Analysen deiner Website zu erhalten.
              </p>

              <div class="form-group">
                <h5>Wie lautet die URL deiner Startseite?</h5>
                <p class="small mb-2">Beginnend mit <code>http://</code> oder <code>https://</code></p>
                <input type="text" v-model="approval.root_url" class="form-control">
              </div>
              <div class="row">
                <!--<div class="col-sm-6">
                  <h5>Wieviele Unterseiten hat deine Website ungefähr?</h5>
                  <p class="small mb-2">
                    Je größer deine Seite, desto länger dauert der Scraping-Vorgang.
                  </p>
                  <div class="btn-group btn-block">
                    <button @click="approval.size = 10" class="btn" :class="approval.size === 10 ? 'btn-primary' : 'btn-light'">
                      &lt; 10
                    </button>
                    <button @click="approval.size = 100" class="btn" :class="approval.size === 100 ? 'btn-primary' : 'btn-light'">
                      &lt; 100
                    </button>
                    <button @click="approval.size = 500" class="btn" :class="approval.size === 500 ? 'btn-primary' : 'btn-light'">
                      &lt; 500
                    </button>
                    <button @click="approval.size = 1000" class="btn" :class="approval.size === 1000 ? 'btn-primary' : 'btn-light'">
                      &lt; 1000
                    </button>
                    <button @click="approval.size = 1001" class="btn" :class="approval.size === 1001 ? 'btn-primary' : 'btn-light'">
                      &gt; 1000
                    </button>
                  </div>
                </div>-->
                <div class="col-sm-6">
                  <div class="form-group">
                    <h5>Sprache</h5>
                    <p class="small mb-2">
                      Wähle zwischen Deutsch und Englisch, um weitere Analysen auf deiner Seite durchführen zu lassen.
                    </p>
                    <select v-model="approval.language" class="form-control">
                      <option value="german" selected>Deutsch</option>
                      <option value="english">Englisch</option>
                      <option value="">Andere Sprache (Keine Analyse)</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <h5>HTML-Filter <span class="badge badge-info">PRO</span></h5>
                    <p class="small mb-2">
                      Wähle einen CSS-Selektor für den Scraper, um wiederkehrende Elemente wie die Navigation zu überspringen.
                    </p>
                    <input type="text" v-model="approval.content_selector" placeholder="z.B. div.content" class="form-control">
                  </div>
                </div>
              </div>

              <div v-if="approval.size >= 500 && !isAdmin" class="row justify-content-center mt-4">
                <div class="col-sm-10 text-center">
                  <i class="fad fa-exclamation-triangle fa-3x text-warning mb-3"></i>
                  <h4>Achtung: Fortfahren auf eigene Gefahr</h4>
                  <p>
                    Das Ergebnis deines Scrapes könnte sehr unübersichtlich werden. <br>
                    Es wird emfohlen deine Seitentypen beispielhaft einzeln zu scrapen um die App besser nutzen zu können.
                    <router-link to="/scraper/single">
                      <i class="fal fa-arrow-right"></i> Einzelne Seite scrapen
                    </router-link>
                  </p>
                  <p class="small text-muted">
                    Eine Funktion um Seiten ein- und auszuschließen kommt in einer der nächsten Versionen.
                  </p>
                </div>
              </div>

              <div class="text-center mt-4">
                <button v-if="step === 0 && !isAdmin"
                        @click="prepareDownload"
                        class="btn btn-primary"
                        :disabled="!isValidUrl"
                >
                  Weiter
                </button>
              </div>

              <div v-if="step === 1 && !isAdmin">
                <div class="text-center my-5">
                  <h5 class="mt-4">Nun müssen wir nur noch sicherstellen, dass du nur deine eigene Website scrapst.</h5>
                  <p>
                    Lade dazu einfach diese Datei auf die angegebene Adresse deines Servers hoch.
                  </p>
                  <div @click="download($event)" class="btn btn-primary">
                    <span v-if="!loading">Approval-Datei downloaden</span>
                    <span v-else><i class="fad fa-circle-notch fa-spin"></i></span>
                    <a id="approval-file-download" style="display:none"></a>
                  </div>
                  <p class="small mt-3">
                    <strong>Hinweis:</strong> Ändere nicht den Dateinamen. Der Scraper sucht nach
                    <code>{{ approval.root_url }}/scraping-approval.json</code>
                  </p>
                </div>
              </div>

              <div v-if="fileDownloaded || isAdmin" class="alert alert-success">
                <h4 class="h5">Sobald alles erledigt ist, klicke auf den Button unten, um den Scraper zu starten.</h4>
                <p>
                  <strong>Wichtig:</strong> Solltest du diese Seite neu aufgerufen haben, musst du deine Approval-Datei neu
                  herunterladen und auf deinem Server überschreiben. Mit jedem Aufruf der Seite wird eine neue, einzigartige
                  ID erzeugt, die den Scraping-Vorgang dieser App zuordnet.
                </p>
                <button class="btn btn-block btn-success" @click="runScraper">
                  <span v-if="initiatingScraper"><i class="fad fa-circle-notch fa-spin"></i> Dies kann eine Weile dauern.</span>
                  <span v-else>Scraper anfordern</span>
                </button>
              </div>

              <div v-if="isAdmin" class="card bg-light">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Approval-ID</label>
                        <input v-model="approval.id" class="form-control" placeholder="Approval-ID" type="text">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Approval-Max_Depth</label>
                        <input v-model.number="approval.max_depth" class="form-control" placeholder="Approval-Max_depth" type="text">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-check">
                        <input v-model="skipRun" class="form-check-input" type="checkbox">
                        <label class="form-check-label">Skip Run</label>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-check">
                        <input v-model="skipApproval" class="form-check-input" type="checkbox">
                        <label class="form-check-label">Skip Approval</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import EditProgress from "@/components/EditProgress";
export default {
  name: 'Scraper',
  components: {EditProgress},
  data() {
    return {
      override: false,
      step: 0,
      editSteps: [
        "Scraper einrichten",
        "Seite bestätigen",
        "Scrape starten",
        "Scraper läuft"
      ],
      loading: true,
      initiatingScraper: false,
      fileDownloaded: false,
      skipRun: false,
      skipApproval: false,
      approval: {
        id: "",
        date: "",
        root_url: "",
        content_selector: "",
        scrape_excludes: [],
        language: "german",
        size: 10,
        max_depth: 2,
        sleep: 2,
        status: "approved"
      },
      depthMapping: {
        1001: 1,
        501: 2,
        101: 3,
        11: 4,
      },
      waitingDots: ""
    }
  },
  computed: {
    nextScrape() {
      return this.nextApproval === 1 ? 'morgen' : ('in ' + this.nextApproval + ' Tagen');
    },
    isValidUrl() {
      return this.approval.root_url.match(
          /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
      );
    },
    size() {
      return this.approval.size;
    },
    scraperUpdate() {
      let update = this.socket.scraperUpdate;
      return update.msg || "Warten auf Lebenszeichen";
    },
  },
  watch: {
    size(newVal) {
      for (let size of Object.keys(this.depthMapping)) {
        if (newVal < size) {
          this.approval.max_depth = this.depthMapping[size];
          break;
        }
      }
    },
    isAdmin(newVal) {
      if (newVal) {
        this.approval.id = "admin";
        this.approval.status = "admin";
      } else {
        this.approval.id = this.generateId();
        this.approval.status = "approved";
      }
    },
    scraperUpdate(newVal) {
      if (newVal === "Fehler") {
        this.scraperError = true;
      } else if (newVal === "Daten wurden übertragen") {
        this.$router.push('/links');
        this.$router.go();
      }
    }
  },
  methods: {
    cancelScrape() {
      this.project.scraper.status = ['cancelled'];
      this.$store.dispatch('project/updateProjectByProp', {prop: "scraper", data: this.project.scraper})
      this.$store.dispatch('project/resetScraper')
    },
    prepareDownload() {
      this.step = 1;
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    download($event) {
      $event.stopPropagation();
      this.loading = true;

      let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.approval));
      let dlAnchorElem = document.getElementById('approval-file-download');
      dlAnchorElem.setAttribute("href", dataStr);
      dlAnchorElem.setAttribute("download", "scraping-approval.json");
      dlAnchorElem.click();

      console.info(this.approval);

      setTimeout(() => {
        this.loading = false;
        this.fileDownloaded = true;
        this.step = 2;
      }, 1000);
    },
    runScraper() {
      this.initiatingScraper = true;
      if (this.isAdmin) {
        if (this.skipRun) {
          this.approval['skip_run'] = true;
        }
        if (this.skipApproval) {
          this.approval['skip_approval'] = true;
        }
      }
      this.$store.dispatch('project/initiateScraper', {type: "full", approval: this.approval})
          .then(() => {
            this.initiatingScraper = false;
            this.step = 3;
          });
    },
    retryScrape() {
      this.approval = this.project.scraper.full[this.project.scraper.full.length - 1];
      this.approval.date = new Date().toISOString();
      this.approval.status = "retry";
      if (confirm("Run approval:" + JSON.stringify(this.approval))) {
        this.project.scraper.status = ["idle"];
        this.runScraper();
      }
    },
    resetScraper() {
      this.project.scraper.status = ["idle"];
      this.$store.dispatch('project/resetScraper')
    }
  },
  beforeMount() {
    Object.assign(this.approval, this.project.scraper.full[this.project.scraper.full.length - 1]);
    this.approval.id = this.generateId();
    this.approval.date = new Date().toISOString();
    this.approval.root_url = this.project.profile.rootUrl;
    if (this.isAdmin) {
      this.override = true;
    }
    if (this.scraperRunning) {
      this.step = 3;
    }
  },
  mounted() {
    let count = 0;
    setInterval(() => {
      count++;
      this.waitingDots = "...".slice(0, count % 4);
    }, 1000);
  },
}
</script>

<style lang="scss">
.transparent {
  color: transparent;
}
</style>